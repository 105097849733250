<script>
import ThemeForm from "@/views/menu/configurations/platform-theme/themeForm";
import Component, { mixins } from "vue-class-component";
import {toastService} from "@/services/toastService.js";


@Component({
  inject: {
    themeService: "themeService"
  }
})
export default class PlatformColorPalette_new extends mixins(ThemeForm) {
  formId = "new-theme-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.themeService.create(this.model);
      toastService.success(this.translations.success.color_palette_created);
      this.$router.push("/configurations/color-palette/list");
    } catch (error) {
      this.errors = this.formatApiErrors(error);
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.color_palette_new;
  }

  created () {
    this.model = {
      title: null,
      first: "#000000",
      second: "#000000",
      third: "#000000",
      fourth: "#000000",
      fifth: "#000000",
      sixth: "#000000",
    };
    this.isReady = true;
  }
}
</script>
