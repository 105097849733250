import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService"
  }
})
export default class ThemeForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get platformLanguagesOptions () {
    return this.platformLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description
      };
    });
  }

  get selectedLanguages () {
    const modelLanguages = (this.model?.languageIds ?? []);
    return this.platformLanguages
      .filter((l) => {
        return modelLanguages.includes(l.id);
      });
  }

  get selectedLanguagesOptions () {
    const selectedLanguages = this.selectedLanguages
      .map((l) => {
        return {
          value: l.id,
          label: l.description
        };
      });
    return selectedLanguages;
  }

  //  TODO: aggiungere gli input color al posto dei text
  get elements() {
    return [
      {
        id: "title",
        label: this.translations.labels.theme_title,
        type: "text",
        required: true
      },
      {
        id: "first",
        label: this.translations.labels.theme_first,
        type: "color",
        required: true
      },
      {
        id: "second",
        label: this.translations.labels.theme_second,
        type: "color",
        required: true
      },
      {
        id: "third",
        label: this.translations.labels.theme_third,
        type: "color",
        required: true
      },
      {
        id: "fourth",
        label: this.translations.labels.theme_fourth,
        type: "color",
        required: true
      },
      {
        id: "fifth",
        label: this.translations.labels.theme_fifth,
        type: "color",
        required: true
      },
          {
        id: "sixth",
        label: this.translations.labels.theme_sixth,
        type: "color",
        required: true
      }
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.theme;
  }

  async created () {
    this.platformLanguages = await this.languageService.list();
  }
}
